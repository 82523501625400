import { FromStringToDate, ToMoneyFormat } from "../../../../../utils/Helper";
import { addTableHeader, breakWord, drawBody } from "../InvoiceGeneratorUtils";
import { IInvoiceDetail } from "../../../Invoice.type";

const PDF_TIDAK_RESMI = (data: IInvoiceDetail) => {
  const header = [
    {
      title: "NO",
      style: {
        width: 10,
      },
    },
    {
      title: "NAMA BARANG",
      style: {
        width: 100,
      },
    },
    {
      title: "SAT",
      style: {
        width: 20,
      },
    },
    {
      title: "HARGA",
      style: {
        width: 20,
      },
    },
    {
      title: "QTY",
      style: {
        width: 12,
      },
    },
    {
      title: "TOTAL",
    },
  ];

  // Data Table
  const dataTable: any[] = [];
  data.invoice_detail.forEach((item, index) => {
    let temp: any[] = [];
    const total = item.price * item.quantity;
    temp.push(index + 1); // No
    temp.push(item?.product?.name || "-"); // Nama Barang
    temp.push(item.unit_type); // Satuan
    temp.push(ToMoneyFormat(item?.price)); // Harga
    temp.push(item?.quantity); // Qty
    temp.push(ToMoneyFormat(Math.round(total) || "0")); // Total
    dataTable.push(temp);
    temp = [];
  });

  // Minimum Rows
  for (let i = dataTable.length; i < 12; i++) {
    let temp: any[] = [];
    temp.push(" ");
    dataTable.push(temp);
  }

  const print = (doc: any) => {
    // Variable
    const docWidth = doc.internal.pageSize.width;
    const docHeight = doc.internal.pageSize.height;
    const currentHeight = 15;
    const colorBlack = "#000000";
    const colorGray = "#4d4e53";
    const fieldTextSize = 10;
    const subLineHeight = 4;
    const tdWidth = (doc.getPageWidth() - 20) / header.length;
    const headerTextSize = 20;
    const labelTextSize = 12;
    const lineHeight = 6;

    const pdfConfig = {
      docWidth,
      docHeight,
      currentHeight,
      colorBlack,
      colorGray,
      fieldTextSize,
      subLineHeight,
      header,
      dataTable,
      tdWidth,
      headerTextSize,
      labelTextSize,
      lineHeight,
    };

    // Header Invoice
    doc.setFontSize(12);
    pdfConfig.currentHeight += breakWord(
      data?.customer_name || "",
      doc,
      pdfConfig.currentHeight - 4,
      pdfConfig.docWidth - 12
    );
    if (data?.pre_order_number) {
      doc.text(
        pdfConfig.docWidth - 12,
        pdfConfig.currentHeight,
        data?.pre_order_number || "",
        "right"
      );
      pdfConfig.currentHeight += 5;
    } else {
      pdfConfig.currentHeight += 2;
    }
    doc.setFontSize(pdfConfig.fieldTextSize);
    doc.setFontSize(17);
    doc.text(
      pdfConfig.docWidth - 10,
      pdfConfig.currentHeight + 2,
      `# ${data.invoice_number}`,
      "right"
    );
    doc.setFontSize(12);
    doc.setTextColor(pdfConfig.colorBlack);
    doc.text(10, pdfConfig.currentHeight + 2, `INVOICE`);
    doc.setFontSize(pdfConfig.fieldTextSize);
    doc.setTextColor(pdfConfig.colorGray);
    pdfConfig.currentHeight += pdfConfig.subLineHeight;
    doc.line(
      10,
      pdfConfig.currentHeight,
      pdfConfig.docWidth - 10,
      pdfConfig.currentHeight
    );
    pdfConfig.currentHeight += 1;

    // Table Header
    addTableHeader({ doc: doc, config: pdfConfig });
    doc.line(
      10,
      pdfConfig.currentHeight,
      pdfConfig.docWidth - 10,
      pdfConfig.currentHeight
    );

    // Table Body
    doc.setFontSize(pdfConfig.fieldTextSize + 1);
    drawBody({ doc: doc, config: pdfConfig });
    doc.line(
      10,
      pdfConfig.currentHeight,
      pdfConfig.docWidth - 10,
      pdfConfig.currentHeight
    );

    // Footer Invoice Left Side
    doc.setFontSize(11);
    pdfConfig.currentHeight += pdfConfig.lineHeight;
    doc.text(
      10,
      pdfConfig.currentHeight,
      "SELURUH TRANSAKSI HARAP DIBAYARKAN FULL AMOUNT"
    );
    doc.setFontSize(11);
    pdfConfig.currentHeight += 6;
    doc.text(10, pdfConfig.currentHeight, "TERMIN PEMBAYARAN :");
    pdfConfig.currentHeight += 6;
    doc.text(10, pdfConfig.currentHeight, `PEMBAYARAN VIA`);
    pdfConfig.currentHeight += 6;
    doc.text(10, pdfConfig.currentHeight, `NO REK:`);
    pdfConfig.currentHeight += 6;
    doc.text(10, pdfConfig.currentHeight, `AN.`);
    pdfConfig.currentHeight += 6;
    doc.text(10, pdfConfig.currentHeight, "NOTE: ..........................");
    pdfConfig.currentHeight += 15;

    // Footer Invoice Right Side
    pdfConfig.currentHeight = pdfConfig.currentHeight - 44;

    //DPP
    doc.setFontSize(12);
    doc.text(
      pdfConfig.docWidth / 1.2,
      pdfConfig.currentHeight,
      "DPP:",
      "right"
    );
    doc.text(
      pdfConfig.docWidth - 10,
      pdfConfig.currentHeight,
      `${ToMoneyFormat(data?.total)}`,
      "right"
    );

    //TAX
    pdfConfig.currentHeight += 5;
    doc.text(
      pdfConfig.docWidth / 1.2,
      pdfConfig.currentHeight,
      "TAX:",
      "right"
    );
    doc.text(
      pdfConfig.docWidth - 10,
      pdfConfig.currentHeight,
      `${Math.round(data?.tax || 0)}`,
      "right"
    );

    //PPH
    pdfConfig.currentHeight += 6;
    doc.text(
      pdfConfig.docWidth / 1.2,
      pdfConfig.currentHeight,
      "PPH:",
      "right"
    );
    doc.text(
      pdfConfig.docWidth - 10,
      pdfConfig.currentHeight,
      `${ToMoneyFormat(Math.round(data?.pph || 0))}`,
      "right"
    );

    //TOTAL
    pdfConfig.currentHeight += 6;
    doc.text(
      pdfConfig.docWidth / 1.2,
      pdfConfig.currentHeight,
      "TOTAL:",
      "right"
    );
    doc.text(
      pdfConfig.docWidth - 10,
      pdfConfig.currentHeight,
      `${ToMoneyFormat(Math.round(data?.grand_total || 0))}`,
      "right"
    );

    // ========================================================
    doc.setFontSize(11);
    pdfConfig.currentHeight += 6;
    doc.text(
      pdfConfig.docWidth - 9,
      pdfConfig.currentHeight,
      "TOTAL PACKING, NOTA, & TGL PACKING :",
      "right"
    );
    pdfConfig.currentHeight += 6;
    doc.text(
      pdfConfig.docWidth - 8,
      pdfConfig.currentHeight,
      "......................................",
      "right"
    );

    // Invoice Tanda Terima
    doc.setFontSize(pdfConfig.fieldTextSize);
    pdfConfig.currentHeight += 15;
    doc.text(10, pdfConfig.currentHeight, "PENERIMA,");
    doc.line(
      10,
      pdfConfig.currentHeight + 15,
      50,
      pdfConfig.currentHeight + 15
    );
    doc.text(
      pdfConfig.docWidth - 10,
      pdfConfig.currentHeight,
      "HORMAT KAMI,",
      "right"
    );
    doc.line(
      pdfConfig.docWidth - 10,
      pdfConfig.currentHeight + 15,
      pdfConfig.docWidth - 50,
      pdfConfig.currentHeight + 15
    );
  };

  return { print };
};

export { PDF_TIDAK_RESMI };
